import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import dataTable from './data-filter/reducers';
import { headerSearchReducer } from './headerSearch/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  headerSearchData: headerSearchReducer,
  dataTable,
});

export default rootReducers;
