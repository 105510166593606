import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../layout/withAdminLayout';
import Dashboard from '../pages/dashboard';
import ReportedEmissions from '../pages/reported-emissions';

const NotFound = lazy(() => import('../pages/404'));
const UserProfile = lazy(() => import('../pages/userProfile'));
const Users = lazy(() => import('../pages/users'));
const Companies = lazy(() => import('../pages/companies'));
const Contracts = lazy(() => import('../pages/contracts'));
const Tables = lazy(() => import('../pages/tables'));
const Uploads = lazy(() => import('../pages/uploads'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/dashboard" element={<Dashboard />} />
        <Route path="user-profile" element={<UserProfile />} />
        <Route path="users" element={<Users />} />
        <Route path="companies" element={<Companies />} />
        <Route path="contracts" element={<Contracts />} />
        <Route path="content/*" element={<Tables />} />
        <Route path="uploads" element={<Uploads />} />
        <Route path="reported-emissions" element={<ReportedEmissions />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
