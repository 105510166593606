/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { Row, Col, Form, Input } from 'antd';
import { ReportedEmissionsPageheaderStyle, ReportedEmissionsTableStyleWrapper } from './style';
import { CardToolbox, Main } from '../style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { getXlsxEmissions } from '../../services/reported-emissions';

function ReportedEmissions() {
  const [form] = Form.useForm();
  const [year, setYear] = useState('');
  const handleSubmit = () => {
    const yearNumber = Number(year);
    getXlsxEmissions(`ghg_emission_datasource_${yearNumber}`, {
      report_year: yearNumber,
    });
  };

  return (
    <>
      <CardToolbox>
        <ReportedEmissionsPageheaderStyle>
          <PageHeader ghost title="Relatório de Emissões" />
        </ReportedEmissionsPageheaderStyle>
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Cards headless>
            <ReportedEmissionsTableStyleWrapper>
              <Form form={form} onFinish={handleSubmit}>
                <Row>
                  <Col xxl={6} xl={6} md={6} xs={6}>
                    <Form.Item
                      label="Ano"
                      name="year"
                      rules={[{ message: 'Este campo é obrigatório', required: true }]}
                    >
                      <Input type="number" onChange={(e) => setYear(e.target.value)} placeholder="Digite o ano" />
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} md={6} xs={6}>
                    <Form.Item label="">
                      <Button htmlType="submit" className="btn-add_new" size="default" type="primary" key="1">
                        Exportar relatório
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </ReportedEmissionsTableStyleWrapper>
          </Cards>
        </Row>
      </Main>
    </>
  );
}

export default ReportedEmissions;
