import api from '../../config/api/api';

export const getTables = async (data) => {
  const response = await api.post('/parameters-tables/get-list', data);
  return response.data;
};

export const getDataTable = async (data) => {
  const response = await api.post('/parameters-tables/get-records', data);
  return response.data;
};

export const getColumnsConfig = async (data) => {
  const response = await api.post('/parameters-tables/get-options', data);
  return response.data;
};
