/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { TopMenuStyle } from './Style';

function TopMenu() {
  const config = useSelector((state) => state.auth.info);

  useEffect(() => {}, [config]);

  useLayoutEffect(() => {
    const active = document.querySelector('.ninjadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <>
            {/* <li className="has-subMenu">
              <a to="#" className="parent">
                
              </a>
            </li> */}
          </>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
