import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { AuthenticationWrap } from './style';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap
          style={{
            backgroundImage: `url("${require('../../static/img/city.jpg')}")`,

            height: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="ninjadash-authentication-wrap">
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
