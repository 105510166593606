import xlsx from 'json-as-xlsx';
import api from '../../config/api/api';

export const getXlsxEmissions = async (filename, infoEmissions) => {
  const settings = { fileName: filename, writeOptions: { type: 'base64', bookType: 'xlsx' } };
  try {
    const { data } = await api.post('reported-emission-exports/getemissionbyyear', infoEmissions);
    xlsx(data, settings);
  } catch (error) {
    console.log(error);
  }
};
