import { UilAngleDown, UilSignout, UilMoon, UilSun, UilUser } from '@iconscout/react-unicons';
import { Avatar, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import { InfoWraper, UserDropDwon } from './Style';
import { logOut } from '../redux/authentication/actionCreator';
import Heading from '../components/heading/heading';
import { Popover } from '../components/popup/popup';
import { changeLayoutMode } from '../redux/themeLayout/actionCreator';
import { getCookiesEncrypted } from '../utility/localStorageControl';
import { Profile } from '../config/Storage';

const HeaderInfo = React.memo(() => {
  const [config, setConfig] = useState('');
  const [letterUser, setLetterUser] = useState('');
  const [theme, setTheme] = useState(true);
  const { info, refresh } = useSelector((state) => state.auth);
  useEffect(() => {
    const mode = Cookies.get('mode');
    dispatch(changeLayoutMode(mode === 'darkMode' ? 'darkMode' : 'lightMode'));

    setTheme(mode !== 'darkMode');

    getInfos();
  }, [info]);

  function getInfos() {
    setConfig({});
    const cookies = getCookiesEncrypted(Profile);

    const data = cookies || info;
    setConfig({ ...data?.user });
    const user = data?.user?.name?.split(' ');
    if (user?.length) {
      const letters = user
        .map((word) => {
          return word[0].toUpperCase();
        })
        .join('')
        .substring(0, 2);
      setLetterUser(letters);
    }
  }

  useEffect(() => {
    getInfos();
  }, [refresh]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{config?.name}</Heading>
            <p>{config?.position}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/user-profile">
              <UilUser />
              Seus dados
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sair
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Switch
        checkedChildren={<UilSun size={18} />}
        unCheckedChildren={<UilMoon size={18} />}
        checked={theme}
        onChange={(e) => {
          setTheme(!theme);
          dispatch(changeLayoutMode(e ? 'lightMode' : 'darkMode'));
        }}
        style={{ backgroundColor: '#1a63a9' }}
      />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar style={{ backgroundColor: '#cce0ff', color: '#1a63a9', fontWeight: 'bold' }}>{letterUser}</Avatar>
            <span className="ninjadash-nav-actions__author--name">{config?.name}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default HeaderInfo;
